import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import FunctionList from './views/FunctionList.vue'
import AddFuelRecordFunction from './views/AddFuelRecordFunction.vue'
import QilkSense from './views/QilkSense.vue'
import QilkSenseAuth from './views/QilkSenseAuth.vue'
import Profile from './views/Profile.vue'
import AuthService from './services/AuthService';
// import AuthService from './services/auth.service';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: Home
    // },
    { path: '/health', component: { template: '<div>good</div>' } },
    {
      path: '/',
      name: 'functionList',
      component: FunctionList
    },
    {
      path: '/qliksense',
      name: 'qlikSense',
      component: QilkSense
    }, 
    {
      path: '/qliksenseauth',
      name: 'qlikSenseAuth',
      component: QilkSenseAuth
    },         
    { path: '/addFuelRecordFunction', name: 'addFuelRecordFunction', component: AddFuelRecordFunction }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ]
});

router.beforeEach((to, from, next) => {
  const authService: AuthService = new AuthService();

  if (to.matched.some(record => record.meta.isSecure)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    //TBD

  } else {
    next();
  }
});

export default router;