import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AuthService from './services/AuthService';
// import AuthService from './services/auth.service';
import BootstrapVue from 'bootstrap-vue'
// import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'


Vue.use(BootstrapVue)
// Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false;

const _authService = new AuthService();
Vue.prototype.$authService = _authService;
Vue.$authService = _authService;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')


