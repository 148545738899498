
































































import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/services/AuthService";
import APIService from "@/services/APIService";
import * as Msal from "msal";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { whetherEmployeeCanAccess } from '@/services/whetherEmployeeCanAccess'

@Component({
  components: {
    // Loading,
  },
})
export default class FunctionList extends Vue {
  public currentUser: string = "";
  public isLoggedIn: boolean = false;
  public id_token: string = "";
  public access_token: string = "";
  public apiResult: string = "";
  private auth = Vue.$authService;
  private apiService = new APIService();
  private isLoading: boolean = false;
  private fullPage: boolean = true;
  public categories = "normal";

  get username(): string {
    return this.currentUser;
  }

  public login() {
    this.auth
      .login()
      .then((loginResponse) => {
        console.log("id_token acquired at: " + new Date().toString());
        console.log(loginResponse);
        if (this.auth.getUser()) {
          // showWelcomeMessage(this.userAgent.getAccount());
          console.log("name: " + this.auth.getUser().name);
          this.isLoggedIn = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  public logout() {
    this.auth.logout();
  }
  public goToEvcr() {
    // window.open("https://www.w3schools.com");
    window.open(process.env.VUE_APP_EVCR_URI, "_self");
  }
  public goToDmp() {
    
    window.open(process.env.VUE_APP_DMP_URI, "_self");
  }
  public async mounted() {
    // this.$i18n.locale = "zh";
    this.$i18n.locale = window.navigator.language.slice(0, 2);
    // console.log("mounted...");

    // this.$refs.login.click();

    // if the user is already logged in you can acquire a token
    if (this.auth.getUser()) {
      this.currentUser=this.auth.getUser().userName;
      console.log(this.currentUser);
      console.log("mounted: user is login");
      this.auth.getAccessToken().then(
        (token) => {
          console.log(token);
          this.isLoggedIn = true;
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      // user is not logged in, you will need to log them in to acquire a token
      console.log("mounted: user needs to login");
      const elem = this.$refs.login as HTMLElement;
      elem.click();
    }
  }

  get whetherEmployeeCanAccess():boolean {
        return whetherEmployeeCanAccess(this.currentUser);
    }


}
