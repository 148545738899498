import * as Msal from 'msal'
import Vue from 'vue'


const authRequest = {
  //scopes: ['openid', 'user.read', 'User.Read.All'] // if calls graph api
  // scopes: ['f18314d9-3bc9-4965-8ecb-a6f4110738f2/.default'] // it has to be this for v2 endpoint
  // scopes: ["user.read"]
  //dev
  // scopes: ["api://82566e80-1f45-400e-bfcb-0782aeebe99e/access_as_user"]
  scopes: [process.env.VUE_APP_API_SCOPE]
  //integ
  // scopes: ["api://da163d2b-9566-4eaf-a58e-a644b1696fab/access_as_user"]
  //prod
  // scopes: ["api://e2e06f27-728d-44f9-9cb8-df95e46d4a85/access_as_user"]
}

export default class AuthService {
  userAgent: Msal.UserAgentApplication

  constructor() {
    var config: any = {
      auth: {
        //dev
        // clientId: 'f18314d9-3bc9-4965-8ecb-a6f4110738f2',
        clientId: process.env.VUE_APP_CLIENT_ID,
        //integ
        // clientId: 'dca8c708-6013-418d-9797-b81d20bc885f',
        //prod
        // clientId: '8fd03a7c-52cc-4e63-9b60-0d415af50269',
        authority: 'https://login.microsoftonline.com/950af356-6025-4fdb-96a0-a9be6b893fec',
        // redirectUri: 'http://localhost:8080',
        // redirectUri: 'https://d3uibumvb8512g.cloudfront.net/',
        redirectUri: process.env.VUE_APP_REDIRECT_URI,
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
      }
    }

    this.userAgent = new Msal.UserAgentApplication(config);
    this.userAgent.handleRedirectCallback(this.authCallback);
  }

  private authCallback(error, response) {
    const authServ = Vue.$authService;
    if (error) {
      console.log('authCallback:' + error);
    } else {
      console.log('authCallback: token type is:' + response.tokenType);
    }
  }
  public login() {
    // this.userAgent.loginRedirect(authRequest)
    // this.userAgent.loginPopup({})
    // return this.userAgent.loginPopup()
    return this.userAgent.loginPopup(authRequest);
  }
  public acquireTokenSilent() {
    return this.userAgent.acquireTokenSilent(authRequest);
  }

  public getUser() {
    return this.userAgent.getAccount()
  }
  public logout() {
    this.userAgent.logout()
  }

  public async isLogin() {
    try {
      await this.userAgent.acquireTokenSilent(authRequest);
    }
    catch (error) {
      console.log('check isLogin error:' + error);
      return false;
    }
    return true;
  }
  public async getAccessToken() {
    return this.userAgent.acquireTokenSilent(authRequest).then(
      accessToken => {
        // throw new Error('Oh no!');
        return accessToken;
      },
      error => {
        return this.userAgent
          .acquireTokenPopup(authRequest)
          .then(
            accessToken => {
              return accessToken;
            },
            err => {
              console.error(err);
            }
          );
      });
  }
  public async getToken() {
    try {
      return await this.userAgent.acquireTokenSilent(authRequest);
    }
    catch (err) {
      //Acquire token silent failure, and send an interactive request
      console.log('getToken failed: ' + err);
      this.userAgent.acquireTokenRedirect(authRequest);

    }
  }
}